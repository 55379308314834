.toolbarButton {
  // stylelint-disable-next-line
  :global .btnIcon {
    margin: 0 6px !important;
  }
}

.refreshBtn {
  display: flex;
  padding: 4px 8px;
}

.clearIcon {
  cursor: pointer;
}
